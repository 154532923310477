import React from "react"

import { Box, Container } from "@mui/material"
import { withStyles } from "@mui/styles"
import PropTypes from "prop-types"

import Menu from "./Menu"
import TopBar from "./TopBar"

const styles = theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.text,
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
  },
  container: {
    maxWidth: "none",
    minHeight: "calc(100vh - 90px)",
  },
})

function Layout(props) {
  const { classes, children } = props
  return (
    <Box className={classes.pageWrapper}>
      <TopBar />
      <Menu />
      <Container className={classes.container}>{children}</Container>
    </Box>
  )
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Layout)
