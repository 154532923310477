const styles = theme => ({
  deviceMobile: {
    paddingRight: 20,
    cursor: "pointer",
    transition: "color .2s linear",
    willChange: "color",

    "&:hover": {
      color: `${theme.palette.secondary.light}`,
    },

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  deviceDesktop: {
    display: "none",

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  titleDialog: {
    color: `${theme.palette.primary.text}`,
  },
  paperDialog: {
    background: `${theme.palette.primary.main}`,
  },
})

export default styles
