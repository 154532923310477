/* eslint-disable no-bitwise */
import React, { memo } from "react"

import { withStyles } from "@mui/styles"
import classNames from "classnames"

import styles from "../styles"

const CircleChart = props => {
  const { phaseLoad, phaseUsagePercent, phaseMaxPower, phaseOverload, classes } = props
  const loadMax = 100
  const calculatePhase = (phase, phaseMax) => (loadMax * (phase / phaseMax)) | 0

  const phaseStyles = {
    r: 80,
    strokeWidth: 30,
    strokeGreen1: "#94D2AB",
    strokeGreen2: "#82CA9D",
    strokeGreen3: "#70C28F",
    strokeYellow: "#FFCC80",
    strokeRed: "#EF9A9A",
    shadow1: "rgba(148,210,171, 1)",
    shadow2: "rgba(130,202,157, 1)",
    shadow3: "rgba(112,194,143, 1)",
  }

  const isPhaseLoad = !!Object.values(phaseLoad).find(p => p > 0)
  const isPhaseOverload = !!Object.values(phaseOverload).find(p => p > 0)

  const pi = 22 / 7
  const circumference = r => 2 * pi * r
  const strokeDasharray = (percent, r) => (circumference(r) * percent) / 100
  const overloadStrokeDasharray = (dynamic, percent, r) => {
    const dynamicStroke = strokeDasharray((dynamic * percent) / 100, r)
    const stroke = strokeDasharray(percent, r)
    return dynamicStroke > stroke ? stroke : dynamicStroke
  }

  const phase2Rotate = (phaseUsagePercent.L1 / 100) * 360
  const phase3Rotate = ((phaseUsagePercent.L1 + phaseUsagePercent.L2) / 100) * 360

  return (
    <svg preserveAspectRatio="none">
      <g id="green-phase" transform="rotate(-90, 120, 120)">
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="80"
          stroke={phaseStyles.strokeGreen1}
          strokeDasharray={`${strokeDasharray(phaseUsagePercent.L1, phaseStyles.r)}, ${circumference(phaseStyles.r)}`}
          strokeDashoffset="1"
          strokeWidth={phaseStyles.strokeWidth}
          style={{ filter: `drop-shadow(0 0 10px ${phaseStyles.shadow1})` }}
          transform="rotate(0,120,120)"
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r={phaseStyles.r}
          stroke={phaseStyles.strokeGreen2}
          strokeDasharray={`${strokeDasharray(phaseUsagePercent.L2, phaseStyles.r)}, ${circumference(phaseStyles.r)}`}
          strokeDashoffset="1"
          strokeWidth={phaseStyles.strokeWidth}
          style={{ filter: `drop-shadow(0 0 10px ${phaseStyles.shadow2})` }}
          transform={`rotate(${phase2Rotate},120,120)`}
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r={phaseStyles.r}
          stroke={phaseStyles.strokeGreen3}
          strokeDasharray={`${strokeDasharray(phaseUsagePercent.L3, phaseStyles.r)}, ${circumference(phaseStyles.r)}`}
          strokeDashoffset="1"
          strokeWidth={phaseStyles.strokeWidth}
          style={{ filter: `drop-shadow(0 0 10px ${phaseStyles.shadow3})` }}
          transform={`rotate(${phase3Rotate},120,120)`}
        />
      </g>
      <g
        className={classNames(classes.circleOpacity, { [classes.show]: isPhaseLoad })}
        id="yellow-phase"
        transform="rotate(-90, 120,120)"
      >
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="70"
          stroke={phaseStyles.strokeYellow}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseLoad.L1, phaseMaxPower.L1),
            phaseUsagePercent.L1,
            70
          )}, ${circumference(70)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform="rotate(0,120,120)"
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="70"
          stroke={phaseStyles.strokeYellow}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseLoad.L2, phaseMaxPower.L2),
            phaseUsagePercent.L2,
            70
          )}, ${circumference(70)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform={`rotate(${phase2Rotate},120,120)`}
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="70"
          stroke={phaseStyles.strokeYellow}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseLoad.L3, phaseMaxPower.L3),
            phaseUsagePercent.L3,
            70
          )}, ${circumference(70)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform={`rotate(${phase3Rotate},120,120)`}
        />
      </g>

      <g
        className={classNames(classes.circleOpacity, { [classes.show]: isPhaseOverload })}
        id="red-phase"
        transform="rotate(-90, 120, 120)"
      >
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="100"
          stroke={phaseStyles.strokeRed}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseOverload.L1, phaseMaxPower.L1),
            phaseUsagePercent.L1,
            100
          )}, ${circumference(100)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform="rotate(0,120, 120)"
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="100"
          stroke={phaseStyles.strokeRed}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseOverload.L2, phaseMaxPower.L2),
            phaseUsagePercent.L2,
            100
          )}, ${circumference(100)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform={`rotate(${phase2Rotate},120,120)`}
        />
        <circle
          cx="120"
          cy="120"
          fill="none"
          r="100"
          stroke={phaseStyles.strokeRed}
          strokeDasharray={`${overloadStrokeDasharray(
            calculatePhase(phaseOverload.L3, phaseMaxPower.L3),
            phaseUsagePercent.L3,
            100
          )}, ${circumference(100)}`}
          strokeDashoffset="1"
          strokeWidth="12"
          transform={`rotate(${phase3Rotate},120,120)`}
        />
      </g>
      <text className="small" fill="#fff" transform="rotate(45)" x="150" y="-130">
        Faza L1
      </text>
      <text className="small" fill="#fff" transform="rotate(45)" x="145" y="-110">
        [{phaseMaxPower.L1.toFixed(0)} W]
      </text>
      <text className="small" fill="#fff" transform="rotate(-45)" x="-30" y="40">
        Faza L3
      </text>
      <text className="small" fill="#fff" transform="rotate(-45)" x="-40" y="60">
        [{phaseMaxPower.L3.toFixed(0)} W]
      </text>
      <text className="small" fill="#fff" x="95" y="245">
        Faza L2
      </text>
      <text className="small" fill="#fff" x="95" y="265">
        [{phaseMaxPower.L2.toFixed(0)} W]
      </text>
    </svg>
  )
}

export default memo(withStyles(styles)(CircleChart))
