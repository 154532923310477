// @ts-nocheck
import React, { useContext } from "react"

import { useTheme } from "@mui/styles"
import { styled } from "@mui/system"
import classNames from "classnames"

import { MenuContext } from "../../contexts/MenuContext"

const StyledWrapper = styled("div")({
  width: 30,
  height: 20,
  position: "relative",
  transform: "rotate(0deg)",
  transition: ".5s ease-in-out",
  cursor: "pointer",
  zIndex: 11,

  "&.open span:nth-child(1)": {
    top: 18,
    width: 0,
    left: "50%",
  },
  "&.open span:nth-child(2)": {
    transform: "rotate(45deg)",
  },
  "&.open span:nth-child(3)": {
    transform: "rotate(-45deg)",
  },
  "&.open span:nth-child(4)": {
    top: 18,
    width: 0,
    left: "50%",
  },
})

const StyledSpan = styled("span")(props => ({
  display: "block",
  position: "absolute",
  height: 4,
  width: "100%",
  background: `${props.background}`,
  borderRadius: 2,
  opacity: 1,
  left: 0,
  transform: "rotate(0deg)",
  transition: ".25s ease-in-out",

  "&:nth-child(1)": {
    top: 0,
  },
  "&:nth-child(2)": {
    top: 8,
  },
  "&:nth-child(3)": {
    top: 8,
  },
  "&:nth-child(4)": {
    top: 16,
  },
}))

const Hamburger = () => {
  const [state, dispatch] = useContext(MenuContext)
  const theme = useTheme()
  const menuBg = theme.palette.secondary.light

  const menuHandler = () => dispatch({ type: "open", payload: !state.open })

  return (
    <StyledWrapper
      className={classNames({
        open: state.open,
      })}
      onClick={menuHandler}
    >
      <StyledSpan background={menuBg} />
      <StyledSpan background={menuBg} />
      <StyledSpan background={menuBg} />
      <StyledSpan background={menuBg} />
    </StyledWrapper>
  )
}

export default Hamburger
