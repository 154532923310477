// @ts-nocheck
import React, { memo } from "react"

import { FormControl, MenuItem, Select as MuiSelect, InputLabel } from "@mui/material"
import { withStyles } from "@mui/styles"

import styles from "./styles"

const Select = props => {
  const { title, items, selected, getValue, classes } = props
  return (
    <FormControl
      className={classes.installation}
      FormControl
      size="small"
      sx={{ m: 1, minWidth: 200 }}
      variant="outlined"
    >
      <InputLabel className={classes.selectLabel} id={`select-${title}`}>
        {title}
      </InputLabel>
      <MuiSelect
        className={classes.select}
        label={title}
        labelId={`select-${title}`}
        onChange={e => getValue(e.target.value)}
        value={selected}
      >
        {items.map(item => (
          <MenuItem key={item.id} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default memo(withStyles(styles)(Select))
