import React, { useContext, useState } from "react"

import {
  Dashboard as DashboardIcon,
  BarChart as BarChartIcon,
  ShowChart as ShowChartIcon,
  ElectricalServices as InstallIcon,
  Logout as LogoutIcon,
  HelpOutline as HelpIcon,
  ExpandMore,
} from "@mui/icons-material"
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useTheme, withStyles } from "@mui/styles"
import { styled } from "@mui/system"
import classNames from "classnames"
import { Link } from "react-router-dom"

import { logout } from "helpers/session"

import { MenuContext } from "../../contexts/MenuContext"
import styles from "./styles"

const StyledWrapper = styled("div")(props => ({
  padding: "50px 0 0",
  position: "fixed",
  top: 0,
  right: "-150%",
  height: "100vh",
  width: "100%",
  background: `${props.background}`,
  boxShadow: `0 0 15px ${props.shadowColor}`,
  transition: "right .2s linear",
  willChange: "right",
  zIndex: 10,

  [props.theme.breakpoints.up("md")]: {
    padding: "50px 20px 0",
    width: 350,
  },

  "&.open": {
    right: 0,
  },
}))

function Menu(props) {
  const theme = useTheme()
  const { classes } = props
  const [open, setOpen] = useState(false)
  const [state, dispatch] = useContext(MenuContext)

  const menuHandler = () => dispatch({ type: "open", payload: false })

  return (
    <StyledWrapper
      background={theme.palette.primary.main}
      className={classNames({ open: state.open })}
      shadowColor={theme.palette.primary.light}
    >
      <nav>
        <List>
          <ListItemButton component={Link} onClick={menuHandler} to="/">
            <ListItemIcon>
              <DashboardIcon sx={{ color: theme.palette.secondary.light }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton component={Link} onClick={menuHandler} to="/analysis">
            <ListItemIcon>
              <BarChartIcon sx={{ color: theme.palette.secondary.light }} />
            </ListItemIcon>
            <ListItemText primary="Analiza" />
          </ListItemButton>
          <ListItemButton component={Link} onClick={menuHandler} to="/monitor">
            <ListItemIcon>
              <ShowChartIcon sx={{ color: theme.palette.secondary.light }} />
            </ListItemIcon>
            <ListItemText primary="Monitor" />
          </ListItemButton>
        </List>
      </nav>
      <Divider sx={{ borderColor: theme.palette.primary.light }} />
      <nav>
        <List>
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
              <HelpIcon sx={{ color: theme.palette.secondary.light }} />
            </ListItemIcon>
            <ListItemText primary="Pomoc" />
            <ExpandMore className={classNames({ [classes.expanded]: open })} />
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="Instrukcja obsługi" />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </nav>
      <footer className={classes.footer}>
        <Divider sx={{ borderColor: theme.palette.primary.light }} />
        <nav>
          <List>
            <ListItemButton component={Link} onClick={menuHandler} to="/installer">
              <ListItemIcon>
                <InstallIcon sx={{ color: theme.palette.secondary.light }} />
              </ListItemIcon>
              <ListItemText primary="Strefa instalatora" />
            </ListItemButton>
            <ListItemButton onClick={logout} to="/login">
              <ListItemIcon>
                <LogoutIcon sx={{ color: theme.palette.secondary.light }} />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </List>
        </nav>
      </footer>
    </StyledWrapper>
  )
}

export default withStyles(styles)(Menu)
