const styles = () => ({
  expanded: {
    transform: "rotate(180deg)",
    transition: "transform .2s linear",
    willChange: "transform",
  },
  footer: {
    position: "absolute",
    width: "calc(100% - 40px)",
    bottom: 50,
  },
})

export default styles
