import { green } from "@mui/material/colors"

const styles = theme => ({
  inline: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  option: {
    cursor: "pointer",
    position: "relative",
    padding: 10,

    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: 2,
      bottom: 0,
      left: 0,
      background: `${theme.palette.primary.light}`,
      opacity: 0,
      transition: "opacity .2s linear",
    },
  },
  active: {
    "&::before": {
      opacity: 1,
    },
  },
})

export default styles
