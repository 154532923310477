/* eslint-disable default-case */
import * as React from "react"

const initialState = {
  token: {
    access: "",
    refresh: "",
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, token: action.payload }
  }
}

const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null,
})

const UserProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return <UserContext.Provider value={[state, dispatch]}>{props.children}</UserContext.Provider>
}

export { UserContext, UserProvider }
