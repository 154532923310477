import { green, orange } from "@mui/material/colors"

const styles = theme => ({
  grid: {
    alignItems: "stretch",
  },
  tnormal: {
    fontSize: "11em",
    position: "absolute",
    top: "185px",
    opacity: 0.2,
  },
  tflipped: {
    fontSize: "11em",
    position: "absolute",
    top: "410px",
    opacity: 0.2,
    transform: "rotate(180deg)",
  },
  box: {
    position: "relative",
    padding: theme.padding.default,
    color: theme.palette.text.secondary,
    background: theme.palette.primary.main,
    boxShadow: `0 0 10px ${theme.palette.primary.light}`,
  },
  chartsAction: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chartLabel: {
    fontSize: 20,
  },
  btn: {
    "& svg": {
      fill: `${theme.palette.secondary.light}`,
    },
    "&:hover": {
      "& svg": {
        fill: `${theme.palette.secondary.main}`,
      },
    },
  },
  title: {
    display: "flex",
    marginBottom: 20,
    textAlign: "center",
    justifyContent: "space-between",
    color: `${theme.palette.text.main}`,

    "& svg": {
      fill: `${theme.palette.text.main}`,
    },
  },
  charWrapper: {
    padding: "30px 0",
    position: "relative",

    "& svg": {
      width: 250,
      height: 270,
      margin: "0 auto",
      position: "relative",
      display: "block",
    },
    "& circle": {
      transition: "all .2s linear",
    },
  },
  slider: {
    padding: "0 20px",
    "& .MuiSlider-markLabel": {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  inline: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",

    "&>div": {
      marginBottom: 15,
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",

      "&>div": {
        marginBottom: 0,
      },
    },
  },
  divider: {
    margin: "10px 0",
    borderColor: "rgba(255, 255, 255, 0.7)",
  },
  verticalLabel: {
    transform: "rotate(-90deg) translate(0, -50%)",
    position: "absolute",
    left: -15,
    top: "50%",
  },
  formGroup: {
    position: "relative",
  },
  formPadding: {
    paddingLeft: 30,
    minHeight: 100,
  },
  input: {
    width: "100%",
    maxWidth: 90,

    "& input": {
      color: `${theme.palette.primary.text}`,
      opacity: ".8",
      textAlign: "center",
    },
    "& fieldset": {
      borderColor: `${theme.palette.primary.text} !important`,
      opacity: ".8 !important",
    },
  },
  p: {
    padding: "10px 0",
  },
  highlightGreen: {
    fontWeight: 600,
    color: green[300],
  },
  sunIcon: {
    color: orange[300],
  },
  slider: {
    color: "#fff",
  },
  circleOpacity: {
    opacity: 0,
    transition: "all .2s linear",
  },
  show: {
    opacity: 1,
  },
  balancerTotal: {
    padding: 20,
    textAlign: "center",
    fontSize: 16,
    color: `${theme.palette.primary.text}`,
    background: `${theme.palette.primary.light}`,
    display: "flex",
    justifyContent: "space-around",
  },
  balancerTotalValue: {
    fontSize: 24,
    padding: "10px 0 0",
  },
  overload: {
    color: `${theme.palette.secondary.main}`,
  },
  savings: {
    color: `${green[300]}`,
  },
  preloader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
})

export default styles
