const styles = theme => ({
  box: {
    color: theme.palette.text.secondary,
    background: theme.palette.primary.main,
    boxShadow: `0 0 10px ${theme.palette.primary.light}`,
    padding: "35px 15px",
    height: "auto",

    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  wrapper: {
    height: "auto",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  image: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: "0 auto",
    maxWidth: 600,

    [theme.breakpoints.up("md")]: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },

    "& #Inverter-to-grid, #Home-to-inventer, #Inverter-to-battery": {
      "& path": {
        transformOrigin: "center",
        transformBox: "fill-box",
        transition: "all .2s linear",
        animation: "$dotFlow 1.8s linear infinite",

        "&:nth-child(1)": {
          animationDelay: "1.6s",
        },
        "&:nth-child(2)": {
          animationDelay: "1.2s",
        },
        "&:nth-child(3)": {
          animationDelay: ".8s",
        },
        "&:nth-child(4)": {
          animationDelay: ".4s",
        },
        "&:nth-child(5)": {
          animationDelay: ".2s",
        },
      },
      "&.rotate>path": {
        transform: "rotate(180deg)",
        "&:nth-child(1)": {
          animationDelay: ".2s",
        },
        "&:nth-child(2)": {
          animationDelay: ".4s",
        },
        "&:nth-child(3)": {
          animationDelay: ".8s",
        },
        "&:nth-child(4)": {
          animationDelay: "1.2s",
        },
        "&:nth-child(5)": {
          animationDelay: "1.4s",
        },
      },
      "&.negative>path": {
        fill: "#e57373",
      },
    },

    "& #Battery": {
      "& #Full": {
        animation: "$battery 1s ease-in-out infinite",
      },
    },

    "& #Phases #L1": {
      "& #Container, #Container_2, #Container_3, #Container_4": {
        animation: "$phaseTest 4s ease-in-out infinite",
      },
    },
    "& #Phases #L2": {
      "& #Container, #Container_2, #Container_3, #Container_4": {
        animation: "$phaseTest 6s .5s ease-in-out infinite",
      },
    },
    "& #Phases #L3": {
      "& #Container, #Container_2, #Container_3, #Container_4": {
        animation: "$phaseTest 2s .3s ease-in-out infinite",
      },
    },
  },

  "@keyframes dotFlow": {
    from: {
      opacity: 0.2,
    },
    to: {
      opacity: 1,
    },
  },

  "@keyframes battery": {
    0: {
      opacity: 1,
    },
    "50%": {
      opacity: 0.2,
    },
    "100%": {
      opacity: 1,
    },
  },

  "@keyframes phaseTest": {
    0: {
      height: 98,
    },
    "25%": {
      height: 140,
    },
    "50%": {
      height: 200,
    },
    "75%": {
      height: 80,
    },
    "100%": {
      height: 20,
    },
  },
})

export default styles
