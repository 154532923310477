// @ts-nocheck
import React from "react"

import { FormControl, TextField } from "@mui/material"
import { withStyles } from "@mui/styles"

import styles from "./styles"

function Input(props) {
  const { type, autoFocus, placeholder, value, name, onChange, error, classes } = props
  return (
    <FormControl className={classes.formControl}>
      <TextField
        autoFocus={autoFocus}
        error={!!error}
        fullWidth
        helperText={error ? error.message : null}
        id="outlined-basic"
        InputLabelProps={{
          className: classes.inputLabel,
        }}
        InputProps={{
          className: classes.input,
        }}
        label={placeholder}
        name={name}
        onChange={onChange}
        type={type || "text"}
        value={value}
        variant="outlined"
      />
    </FormControl>
  )
}

export default withStyles(styles)(Input)
