import { green, orange, red } from "@mui/material/colors"

const styles = theme => ({
  grid: {
    // [theme.breakpoints.down('sm')]: {
    // width: 'calc(100% - 20px)',
    // },
    alignItems: "stretch",
  },
  tnormal: {
    fontSize: "11em",
    position: "absolute",
    top: "185px",
    opacity: 0.2,
  },
  tflipped: {
    fontSize: "11em",
    position: "absolute",
    top: "410px",
    opacity: 0.2,
    transform: "rotate(180deg)",
  },
  box: {
    overflow: "hidden",
    position: "relative",
    padding: theme.padding.default,
    color: theme.palette.text.secondary,
    background: theme.palette.primary.main,
    boxShadow: `0 0 10px ${theme.palette.primary.light}`,
  },
  chartsAction: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    "& svg": {
      fill: `${theme.palette.secondary.light}`,
    },
    "&:hover": {
      "& svg": {
        fill: `${theme.palette.secondary.main}`,
      },
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(255,255,255,.2)",
    padding: "8px 0",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  itemLabel: {
    fontSize: 12,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "uppercase",
    color: `${theme.palette.secondary.main}`,

    "& svg": {
      fill: `${theme.palette.secondary.main}`,
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    paddingTop: 10,
    fontSize: 10,
    color: "rgba(255,255,255, 0.5)",
    zIndex: 0,
    textAlign: "right",

    "&::before": {
      content: "''",
      position: "absolute",
      left: "-100%",
      top: 0,
      height: 1,
      width: "300%",
      background: "rgba(255,255,255, 0.2)",
    },
  },
  success: {
    color: `${green[400]}`,
  },
  warning: {
    color: `${orange[400]}`,
  },
  error: {
    color: `${red[400]}`,
  },

  link: {
    color: `${orange[400]}`,
    textDecoration: "none",
  },
})

export default styles
