import React, { memo } from "react"

import { Box, Paper } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import classNames from "classnames"

import styles from "./styles"
import HomeWithBatter from "./SVG/HomeWithBatter"
import HomeWithoutBatter from "./SVG/HomeWithoutBatter"

const HomeAnimation = props => {
  const { battery, isOverflow, status, classes } = props

  return (
    <Box className={classes.wrapper}>
      <Paper className={classNames(classes.box, classes.boxHeight)}>
        {battery ? (
          <HomeWithBatter negative={isOverflow} rotate={isOverflow} status={status} />
        ) : (
          <HomeWithoutBatter negative={isOverflow} rotate={isOverflow} status={status} />
        )}
      </Paper>
    </Box>
  )
}

export default memo(withStyles(styles)(HomeAnimation))
