const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 15px ${theme.palette.primary.light}`,
    marginBottom: theme.margin.default,
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "50%",
  },
  toolBar: {
    justifyContent: "space-between",
  },
  logo: {
    maxWidth: 150,
  },
  notifications: {
    marginRight: 30,
    cursor: "pointer",
  },
  badge: {
    background: `${theme.palette.secondary.light}`,
  },
  installation: {
    marginRight: 20,
    maxWidth: 300,
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  select: {
    color: "#fff",
    "& svg": {
      fill: "#fff",
    },
    "& fieldset": {
      borderColor: "#fff",
    },
    "& legend": {
      display: "none",
    },
  },
  selectLabel: {
    color: `${theme.palette.text.secondary}`,
    background: `${theme.palette.primary.main}`,
    padding: "0 5px",
    top: -4,
  },
})

export default styles
