// @ts-nocheck
import React from "react"

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

import LoginPage from "pages/LoginPage/LoginPage"

import Layout from "./components/Layout"
import AnalysisPage from "./pages/AnalysisPage"
import HomePage from "./pages/HomePage"
import InstallerPage from "./pages/InstallerPage"
import MonitorPage from "./pages/MonitorPage"

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const token = localStorage.getItem("token")
  if (!token) {
    return <Navigate replace to={redirectPath} />
  }

  return children
}

export default () => (
  <BrowserRouter>
    <Routes>
      <Route element={<LoginPage />} path="/login" />
    </Routes>

    <Layout>
      <Routes>
        <Route
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
          path="/"
        />
        <Route
          element={
            <ProtectedRoute>
              <AnalysisPage />
            </ProtectedRoute>
          }
          path="/analysis"
        />
        <Route
          element={
            <ProtectedRoute>
              <MonitorPage />
            </ProtectedRoute>
          }
          path="/monitor"
        />
        <Route
          element={
            <ProtectedRoute>
              <InstallerPage />
            </ProtectedRoute>
          }
          path="/installer"
        />
      </Routes>
    </Layout>
  </BrowserRouter>
)
