// @ts-nocheck
import React, { useContext } from "react"

import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Controller, useForm } from "react-hook-form"

import Input from "components/Input"
import { postRequest } from "helpers/axios"
import { URL_TOKEN } from "helpers/urls"

import { UserContext } from "../../contexts/UserContext"
import logo from "../../images/logo.svg"
import styles from "./styles"

const LoginPage = props => {
  const { classes } = props
  const [, dispatchToken] = useContext(UserContext)

  const { handleSubmit, control } = useForm()

  const saveToken = token => {
    localStorage.setItem("token", token.data.access)
    localStorage.setItem("refresh", token.data.refresh)
    dispatchToken({ type: "SET_TOKEN", payload: token.data })
    window.location.href = "/"
  }

  const onSubmit = data => postRequest(URL_TOKEN, data).then(res => saveToken(res))

  return (
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={12}>
        <Box className={classes.background}>
          <Paper className={classes.wrapper}>
            <img alt="wellsolar" className={classes.logo} src={logo} />
            <Typography className={classes.text} variant="h6">
              Logowanie do panelu zarządzania
            </Typography>
            <Divider className={classes.divider} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => <Input {...field} {...fieldState} placeholder="Email" />}
                rules={{ required: "Email jest wymagany" }}
              />
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <Input {...field} {...fieldState} placeholder="Hasło" type="password" />
                )}
                rules={{ required: "Hasło jest wymagane" }}
              />
              <div className={classes.formFooter}>
                <Button
                  className={classes.btn}
                  disableFocusRipple
                  disableRipple
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
              </div>
            </form>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(LoginPage)
