/* eslint-disable array-callback-return */
// @ts-nocheck
/* eslint-disable no-shadow */
import React, { memo } from "react"

import withStyles from "@mui/styles/withStyles"

import BoxFilterItem from "./BoxFilterItem"
import styles from "./styles"

const BoxFilter = props => {
  const { params, getFilters, classes } = props

  return (
    <div className={classes.paramWrapper}>
      {params &&
        params.map(param => (
          <BoxFilterItem
            getFilters={getFilters}
            id={`param-${param.name}`}
            inputLabelId={`param-${param.name}-${param.id}`}
            inputName={param.name}
            items={param}
          />
        ))}
    </div>
  )
}

export default memo(withStyles(styles)(BoxFilter))
