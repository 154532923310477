/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from "react"

import { Dashboard as DashboardIcon } from "@mui/icons-material"
import SolarPowerIcon from "@mui/icons-material/SolarPower"
import { Grid, Switch, FormGroup, FormControlLabel, Paper, Typography } from "@mui/material"
import { green, red, orange } from "@mui/material/colors"
import { withStyles } from "@mui/styles"
import classNames from "classnames"
import moment from "moment"
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Brush } from "recharts"

import { getRequest } from "helpers/axios"
import { URL_GRAPH } from "helpers/urls"

import HomeAnimation from "../../../components/animations/HomeAnimation"
import PowerMeter from "../../../components/PowerMeter"
import Title from "../../../components/Title"
import { DeviceContext } from "../../../contexts/DeviceContext"
import Overview from "./Overview"
import styles from "./styles"

const HOUR = 1000 * 60 * 60

const MainPage = props => {
  const { classes } = props
  const [battery, setBattery] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)
  const [status, setStatus] = useState("disconnect")
  const [statusText, setStatusText] = useState("Brak połączenia")
  const [liveData, setLiveData] = useState([])

  const [devices] = useContext(DeviceContext)

  const [enConsumed, setEnConsumed] = useState(0)
  const [enGiven, setEnGiven] = useState(0)

  // TODO: status zostanie przywrocony
  // const getDeviceStatus = () => {
  //   getRequest(`/api/status/`).then(res => {
  //     res.data.connected ? setStatus("connected") : setStatus("disconnect")
  //     res.data.connected ? setStatusText("Połączono") : setStatusText("Brak połączenia")
  //   })
  // }
  // useEffect(() => {
  //   getDeviceStatus()
  //   const interval = setInterval(() => getDeviceStatus(), 5000)
  //   return () => clearInterval(interval)
  // }, [])

  const getDeviceStatus = () => {
    devices.active ? setStatus("connected") : setStatus("disconnect")
    devices.active ? setStatusText("Połączono") : setStatusText("Brak połączenia")
  }
  useEffect(() => {
    getDeviceStatus()
    const interval = setInterval(() => getDeviceStatus(), 5000)
    return () => clearInterval(interval)
  }, [devices.active])

  const getLiveData = () =>
    getRequest(URL_GRAPH).then(res => {
      setLiveData(res.data)
    })

  useEffect(() => {
    getLiveData()
    const interval = setInterval(() => getLiveData(), HOUR)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const startDate = new Date()
    const endDate = new Date()
    startDate.setHours(0, 0, 0, 0)

    const resultProductData = liveData.filter(a => {
      const date = new Date(a.time)
      return date >= startDate && date <= endDate
    })

    const energy_given = resultProductData.reduce((accumulator, object) => accumulator + object.energy_given, 0)
    const energy_consumed = resultProductData.reduce((accumulator, object) => accumulator + object.energy_consumed, 0)
    setEnGiven(Math.abs(liveData[0]?.energy_given - energy_given / resultProductData.length))
    setEnConsumed(Math.abs(liveData[0]?.energy_consumed - energy_consumed / resultProductData.length))
  }, [liveData])

  const CustomTooltip = props => {
    const { active, payload } = props

    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.label1}>{`Moc pobrana: ${payload?.[0].value}`} kW</p>
          <p className={classes.label2}>{`Moc oddana: ${payload?.[1].value}`} kW</p>
        </div>
      )
    }

    return null
  }

  return (
    <Grid item xs={12}>
      <Title header="Dashboard" Icon={DashboardIcon} status={status} statusText={statusText} />
      <Grid alignItems="stretch" container spacing={2}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <PowerMeter
                color={green[300]}
                data={Number(enGiven) || 0}
                Icon={SolarPowerIcon}
                title="Wyprodukowana energia"
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <PowerMeter
                color={red[300]}
                data={Number(enConsumed) || 0}
                Icon={SolarPowerIcon}
                title="Energia pobrana z sieci"
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <PowerMeter color={orange[300]} data={23123} Icon={SolarPowerIcon} title="Energia pobrana z OZE" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sx={{ position: "relative" }} xs={12}>
          <Overview setIsOverflow={setIsOverflow} />
        </Grid>
        <Grid item md={6} sx={{ position: "relative" }} xs={12}>
          <div className={classes.staticHeight}>
            <FormGroup sx={{ position: "absolute", top: 15, right: 0 }}>
              <FormControlLabel
                control={<Switch />}
                label="turn on battery"
                onChange={() => setBattery(!battery)}
                value={battery}
              />
            </FormGroup>
            <HomeAnimation battery={battery} isOverflow={isOverflow} status={status} />
          </div>
        </Grid>
        <Grid item md={12} sx={{ position: "relative" }} xs={12}>
          <Paper className={classNames(classes.box, classes.boxHeight)}>
            <Typography className={classes.title} variant="body1">
              LIVE DATA
            </Typography>
            <ResponsiveContainer height={350} width="100%">
              <BarChart data={liveData} margin={{ top: 20, right: 0, left: -40, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  angle={45}
                  dataKey="time"
                  domain={["auto", "auto"]}
                  tickFormatter={date => moment(date).format("HH:mm")}
                  tickMargin={15}
                />
                <YAxis />
                <Bar dataKey="power_consumed" fill="#ee9a9a" />
                <Bar dataKey="power_given" fill="#82ca9d" />
                {/* <Brush stroke="#255475" y={300} /> */}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(MainPage)
