const styles = theme => ({
  header: {
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginRight: 5,
  },

  inlineHeader: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },

  dot: {
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: 10,
    width: 10,
    height: 10,
    background: "#e57373",
    marginRight: 5,
  },
  disconnect: {
    background: "#e57373",
  },
  connected: {
    background: "#81c784",
  },
  connecting: {
    colbackgroundor: "#ffb74d",
  },
})

export default styles
