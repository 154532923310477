// @ts-nocheck
import React from "react"

import { StyledEngineProvider } from "@mui/material/styles"
import { ThemeProvider } from "@mui/styles"

import "./App.css"

import { DeviceProvider } from "./contexts/DeviceContext"
import { MenuProvider } from "./contexts/MenuContext"
import { NotificationsProvider } from "./contexts/Notifications"
import { UserProvider } from "./contexts/UserContext"
import Routes from "./routes"
import theme from "./theme"

const App = () => (
  <StyledEngineProvider injectFirst>
    <UserProvider>
      <NotificationsProvider>
        <DeviceProvider>
          <MenuProvider>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </MenuProvider>
        </DeviceProvider>
      </NotificationsProvider>
    </UserProvider>
  </StyledEngineProvider>
)

export default App
