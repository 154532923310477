const styles = theme => ({
  wrapper: {
    padding: 0,
    maxHeight: 0,
    overflow: "hidden",
    position: "absolute",
    top: "100%",
    right: 0,
    maxWidth: 600,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    boxShadow: `-7px 7px 20px -3px ${theme.palette.primary.light}`,
    transition: "max-height .2s linear",
    willChange: "max-height",
    zIndex: 10,
  },

  show: {
    maxHeight: 400,
    overflow: "auto",
  },

  item: {
    padding: "20px 15px",
    borderBottom: `1px solid ${theme.palette.primary.light}`,

    "&:last-child": {
      borderBottom: 0,
    },
  },

  icon: {
    marginRight: 10,
  },
})

export default styles
