import React, { memo } from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

import Select from "../Select"
import styles from "./styles"

const SwitchModal = props => {
  const { open, handleClose, getValue, items, selected, classes } = props

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={{
        paper: classes.paperDialog,
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle className={classes.titleDialog} id="alert-dialog-title">
        Wybierz urządzenie
      </DialogTitle>
      <DialogContent>
        <Select getValue={getValue} items={items} selected={selected} title="Urządzenie" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(withStyles(styles)(SwitchModal))
