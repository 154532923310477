import React, { useState, useEffect } from "react"

import { Box, Paper, Typography } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { styled } from "@mui/system"
import Odometer from "react-odometerjs"

import styles from "./styles"

const PowerMeter = props => {
  const StyledTitle = styled(Typography)(color => ({
    color: `${color}`,
  }))

  const { classes, title, unit = "kWh", description, currency = "PLN", Icon, data, color, spacing = 0 } = props

  return (
    <>
      <Paper className={classes.paper} style={{ marginBottom: spacing * 10 }}>
        <Icon className={classes.icon} style={{ fill: color }} />
        <Box className={classes.box}>
          <StyledTitle className={classes.title} color={color} gutterBottom>
            {title}
          </StyledTitle>
          <Box className={classes.odometer} style={{ color: `${color}` }}>
            <Odometer format="(.ddd),ddd" theme="minimal" value={data} />
            <div className={classes.unit}>{unit}</div>
          </Box>
          {description && (
            <Box className={classes.price} style={{ color: `${color}` }}>
              {description}:{" "}
              <strong>
                {currency} {(data * 0.01).toPrecision(3)}
              </strong>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  )
}

export default withStyles(styles)(PowerMeter)
