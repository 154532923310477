// @ts-nocheck
import React, { useEffect, useState } from "react"

import withStyles from "@mui/styles/withStyles"

import styles from "./styles"
import TimeTab from "./TimeTab"

const TimeTabs = props => {
  const { getTimeFilter, classes } = props
  const [active, setActive] = useState("day")

  useEffect(() => getTimeFilter(active), [active])

  return (
    <ul className={classes.inline}>
      <TimeTab name="day" onClick={() => setActive("day")} selected={active === "day"} />
      <TimeTab name="week" onClick={() => setActive("week")} selected={active === "week"} />
      <TimeTab name="month" onClick={() => setActive("month")} selected={active === "month"} />
      <TimeTab name="year" onClick={() => setActive("year")} selected={active === "year"} />
    </ul>
  )
}

export default withStyles(styles)(TimeTabs)
