import React, { useState } from "react"

import { OnDeviceTraining as SwitchDeviceIcon } from "@mui/icons-material"
import { Icon } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

import Select from "../Select"
import styles from "./styles"
import SwitchModal from "./SwitchModal"

const SwitchDevice = props => {
  const { setActiveDevice, devices, classes } = props
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(!open)
  const handleClose = () => setOpen(false)

  return (
    <>
      <div className={classes.deviceDesktop}>
        <Select getValue={setActiveDevice} items={devices.data} selected={devices.active} title="Urządzenie" />
      </div>
      <Icon className={classes.deviceMobile} onClick={handleOpen}>
        <SwitchDeviceIcon />
      </Icon>
      <SwitchModal
        getValue={setActiveDevice}
        handleClose={handleClose}
        items={devices.data}
        open={open}
        selected={devices.active}
      />
    </>
  )
}

export default withStyles(styles)(SwitchDevice)
