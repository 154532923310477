// @ts-nocheck
import React from "react"

import { Bolt as BoltIcon } from "@mui/icons-material/"
import { Grid, Paper, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"

// import MeterCard from "../../../components/MeterCard"
import styles from "../styles"
import EnergyFlowChart from "./EnergyFlowChart"

function Overview(props) {
  const { setIsOverflow, classes } = props

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              PHASE USAGE <BoltIcon />
            </Typography>
            <EnergyFlowChart setIsOverflow={setIsOverflow} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Overview)
