/* eslint-disable default-case */
import * as React from "react"

const initialState = {
  data: [],
  active: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DEVICES":
      return { ...state, data: action.payload }
    case "SET_ACTIVE":
      return { ...state, active: action.payload }
  }
}

const DeviceContext = React.createContext({
  state: initialState,
  dispatch: () => null,
})

const DeviceProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return <DeviceContext.Provider value={[state, dispatch]}>{props.children}</DeviceContext.Provider>
}

export { DeviceContext, DeviceProvider }
