const styles = theme => ({
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  groupTitle: {
    fontSize: 18,
    padding: "6px 16px",
  },
  item: {
    padding: "0 6px",
  },
  paramWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  formControl: {
    marginRight: 20,
    width: "calc(25% - 15px)",

    [theme.breakpoints.up("lg")]: {
      "&:nth-child(4n)": {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "calc(33% - 15px)",

      "&:nth-child(3n)": {
        marginRight: 0,
      },
    },
    "@media screen and (max-width: 700px)": {
      width: "calc(50% - 15px)",

      "&:nth-child(3n)": {
        marginRight: 20,
      },

      "&:nth-child(2n)": {
        marginRight: 0,
      },
    },
  },
  select: {
    color: "#fff",
    width: "100%",
    margin: "0 0 20px",

    "& svg": {
      fill: "#fff",
    },
    "& fieldset": {
      borderColor: "#fff",
    },
    "& legend": {
      display: "none",
    },
  },
  selectLabel: {
    fontSize: 16,
    color: `${theme.palette.text.secondary}`,
    background: `${theme.palette.primary.main}`,
    padding: "0 5px",
    opacity: ".5",
    top: -2,

    "&[data-shrink=true]": {
      opacity: "1",
    },
  },
  checkbox: {
    "& svg": {
      color: "#fff",
    },
  },
})

export default styles
