// @ts-nocheck
import React, { memo } from "react"

import Grid from "@mui/material/Grid"
import { withStyles } from "@mui/styles"

import MainPage from "./Sections/MainPage"
import styles from "./styles"

const HomePage = props => {
  const { classes } = props

  return (
    <Grid className={classes.grid} container spacing={3}>
      <MainPage />
    </Grid>
  )
}

export default memo(withStyles(styles)(HomePage))
