// @ts-nocheck
/* eslint-disable no-unused-expressions */
import React, { memo, useContext, useEffect } from "react"

import { Notifications as NotificationsIcon } from "@mui/icons-material"
import { AppBar, Badge, Typography, Toolbar, Box } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { useJwt } from "react-jwt"
import { Link } from "react-router-dom"

import { logout } from "helpers/session"
import { URL_TOKEN_REFRESH, URL_DEVICES } from "helpers/urls"

import { DeviceContext } from "../../contexts/DeviceContext"
import { NotificationsContext } from "../../contexts/Notifications"
import { getRequest, postRequest } from "../../helpers/axios"
import logo from "../../images/logo.svg"
import Hamburger from "../Hamburger"
import Notifications from "../Notifications"
import SwitchDevice from "../SwitchDevice"
import styles from "./styles"

const TopBar = props => {
  const { classes } = props
  const [state, dispatch] = useContext(NotificationsContext)
  const [devices, deviceDispatch] = useContext(DeviceContext)
  const token = localStorage.getItem("token")
  const refresh = localStorage.getItem("refresh")

  const { reEvaluateToken, isExpired } = useJwt(token)

  if (isExpired && token) {
    postRequest(URL_TOKEN_REFRESH, { refresh })
      .then(res => {
        const { status } = res.response

        switch (status) {
          case 401:
            logout()
            break
          case 400:
          default:
            reEvaluateToken(res.data.access)
        }
      })
      .catch(err => err)
  }

  const isSingle = localDevices => localDevices.length > 0 && localDevices.length < 2

  const getDevices = () => {
    getRequest(URL_DEVICES).then(res => {
      const resDevices = res.data.map(device => ({
        id: device.id,
        name: device.device_id,
        value: device.id,
      }))
      deviceDispatch({ type: "SET_DEVICES", payload: resDevices })
      isSingle(resDevices) && setActiveDevice(resDevices[0])
    })
  }
  useEffect(() => {
    if (token) {
      const device = JSON.parse(localStorage.getItem("device"))
      device && setActiveDevice(device)
      getDevices()
    }
  }, [token])

  const setActiveDevice = device => {
    localStorage.setItem("device", JSON.stringify(device))
    deviceDispatch({ type: "SET_ACTIVE", payload: device })
  }

  return (
    <AppBar className={classes.appBar} position="relative">
      <Toolbar className={classes.toolBar}>
        <Typography component={Link} noWrap to="/" variant="h6">
          <img alt="Wellsolar - panel sterowania" className={classes.logo} src={logo} />
        </Typography>
        <Box className={classes.rightSide}>
          <SwitchDevice devices={devices} setActiveDevice={setActiveDevice} />
          <Badge
            badgeContent={3}
            classes={{ badge: classes.badge }}
            className={classes.notifications}
            onClick={() => dispatch({ type: "SET_OPEN", payload: { open: !state.open } })}
          >
            <NotificationsIcon />
          </Badge>
          <Hamburger />
        </Box>
      </Toolbar>
      <Notifications />
    </AppBar>
  )
}

export default memo(withStyles(styles)(TopBar))
