const styles = theme => ({
  formControl: {
    maxWidth: 300,
    display: "block",
    margin: "0 auto 20px",
  },
  installation: {
    marginRight: 20,
    maxWidth: 300,
  },
  select: {
    color: "#fff",
    width: "100%",

    "& svg": {
      fill: "#fff",
    },
    "& fieldset": {
      borderColor: "#fff",
    },
    "& legend": {
      display: "none",
    },
  },
  selectLabel: {
    color: `${theme.palette.text.secondary}`,
    background: `${theme.palette.primary.main}`,
    padding: "0 5px",
    opacity: ".5",
    top: -2,

    "&[data-shrink=true]": {
      opacity: "1",
    },
  },
})

export default styles
