/* eslint-disable default-case */
import * as React from "react"

const initialState = {
  open: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "open":
      return { ...state, open: action.payload }
  }
}

const MenuContext = React.createContext({
  state: initialState,
  dispatch: () => null,
})

const MenuProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return <MenuContext.Provider value={[state, dispatch]}>{props.children}</MenuContext.Provider>
}

export { MenuContext, MenuProvider }
