const styles = theme => ({
  formControl: {
    maxWidth: 300,
    display: "block",
    margin: "0 auto 20px",
  },

  input: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.text.secondary}`,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.text.primary}`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.text.primary}`,
    },

    "& input": {
      color: `${theme.palette.text.secondary}`,
    },
  },

  inputLabel: {
    color: "rgba(255,255,255, .3)",

    "&.Mui-focused": {
      color: "rgba(255,255,255, .3)",
    },
  },
})

export default styles
