import React from "react"

import { Monitor as MonitorIcon } from "@mui/icons-material/"
import { Divider, Grid, List, ListItem, ListItemText, Paper, TextField, Typography } from "@mui/material"
import { purple } from "@mui/material/colors"
import { withStyles } from "@mui/styles"

import Switch from "../../components/Switch"
import Title from "../../components/Title"
import styles from "../HomePage/styles"

const MonitorPage = props => {
  const { classes } = props

  return (
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={12}>
        <Title header="Monitor" Icon={MonitorIcon} />
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <Paper className={classes.box}>
                <Typography className={classes.title} variant="body1">
                  NAPIĘCIE
                </Typography>
                <List>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Napięcie sieci -&nbsp;faza&nbsp;L1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="233 V"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Napięcie sieci -&nbsp;faza&nbsp;L2" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="236 V"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Napięcie sieci -&nbsp;faza&nbsp;L1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="228 V"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem style={{ color: purple[200] }}>
                    <ListItemText primary="Napięcie PV 1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="623 V"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[300] }}>
                    <ListItemText primary="Napięcie PV 2" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="488 V"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem style={{ color: purple[300] }}>
                    <ListItemText primary="Napięcie DC" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="801 V"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper className={classes.box}>
                <Typography className={classes.title} variant="body1">
                  MOCE GENEROWANE
                </Typography>
                <List>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc PV 1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="4.85 kW"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc PV 2" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="3.44 kW"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc generowana faza&nbsp;L1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="2,73 kW"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[200] }}>
                    <ListItemText primary="Moc generowana faza&nbsp;L2" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="2.78 kW"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[300] }}>
                    <ListItemText primary="Moc generowana faza&nbsp;L3" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="2,78 kW"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem style={{ color: purple[300] }}>
                    <ListItemText primary="Moc generowana (SUMA)" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="8.29 kW"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper className={classes.box}>
                <Typography className={classes.title} variant="body1">
                  MOCE ZUŻYWANE (POBIERANE)
                </Typography>
                <List>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc zużywana faza&nbsp;L1" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="1,5 kW"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc zużywana faza&nbsp;L2" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="2,6 kW"
                    />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Moc zużywana faza&nbsp;L3" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="2,73 kW"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem style={{ color: purple[200] }}>
                    <ListItemText primary="Moc zużywana (SUMA)" />
                    <TextField
                      className={classes.input}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                      value="6.83 kW"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper className={classes.box}>
                <Typography className={classes.title} variant="body1">
                  &nbsp;
                </Typography>
                <List>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Line Balancer" />
                    <Switch />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Power Management" />
                    <Switch />
                  </ListItem>
                  <ListItem style={{ color: purple[100] }}>
                    <ListItemText primary="Power output (AUX)" />
                    <Switch />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(MonitorPage)
