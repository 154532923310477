// @ts-nocheck
import React, { useState, useCallback, useEffect, useContext } from "react"

import { BarChart as AnalysisIcon, ChevronRight as NextIcon, ChevronLeft as PrevIcon } from "@mui/icons-material"
import { IconButton, Grid, Paper, Typography, LinearProgress } from "@mui/material"
import { green, red } from "@mui/material/colors"
import { withStyles } from "@mui/styles"
import moment from "moment"
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Area, Tooltip, Legend } from "recharts"

import { getRequest } from "helpers/axios"
import { isEmpty } from "helpers/objectCheck"
import { URL_PARAMETERS, URL_DATA_UUID } from "helpers/urls"

import BoxFilter from "../../components/BoxFilter"
import TimeTabs from "../../components/TimeTabs"
import Title from "../../components/Title"
import { DeviceContext } from "../../contexts/DeviceContext"
import { UserContext } from "../../contexts/UserContext"
import graphColor from "../../graphColor"
import styles from "../HomePage/styles"

const MAX_RANDOM = graphColor.length

const AnalysisPage = props => {
  const { classes } = props
  const [data, setData] = useState([])
  const [params, setParams] = useState([])
  const [timeFilter, setTimeFilter] = useState("")
  const [filters, setFilters] = useState([])
  const [preloader, setPreloader] = useState(false)
  const [token] = useContext(UserContext)
  const [deviceState] = useContext(DeviceContext)

  const getRandomInt = () => Math.floor(Math.random() * MAX_RANDOM)
  const removeDuplicates = array => [...new Set(array)]

  const getParams = () => getRequest(URL_PARAMETERS).then(res => setParams(res.data))
  useEffect(() => getParams(), [])

  const getData = useCallback(() => {
    if (isEmpty(deviceState.active) || isEmpty(params)) return

    setPreloader(true)
    const uuid = deviceState.active
    const collectData = []
    const resFilters = Promise.all(
      filters.map(filter =>
        getRequest(URL_DATA_UUID(uuid), token.access, {
          param: filter,
          since: timeFilter,
        })
      )
    )
    resFilters
      .then(res =>
        res.map(r => {
          const resData = r.data
          collectData.push({ name: resData[0].name, data: resData, color: graphColor[getRandomInt()] })
          return resData
        })
      )
      .finally(() => {
        setPreloader(false)
        setData([...collectData])
      })
  }, [deviceState, params, filters])

  useEffect(() => {
    const timer = setTimeout(() => getData(), 500)
    return () => clearTimeout(timer)
  }, [getData])

  const timeFilterHandler = useCallback(filter => setTimeFilter(filter), [filters])
  const filterHandler = useCallback(
    filter => {
      console.log(filters)
      console.log(filter)
      setFilters(removeDuplicates([...filters, ...filter]))
    },
    [filters]
  )
  const formatXAxis = tickItem => moment(tickItem).format("HH:mm")

  return (
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={12}>
        <Title header="Analiza" Icon={AnalysisIcon} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.box}>
          {preloader && <LinearProgress className={classes.preloader} />}
          <Typography className={classes.title} variant="body1">
            FILTRY
          </Typography>
          <BoxFilter getFilters={filterHandler} params={params} />
        </Paper>
        <Paper className={classes.box}>
          <div>
            <TimeTabs getTimeFilter={timeFilterHandler} />
          </div>
          <div className={classes.chartsAction}>
            {/* <IconButton className={classes.btn} onClick={() => changePageHandler("prev")}>
              <PrevIcon fontSize="large" />
            </IconButton> */}
            {/* <div className={classes.chartLabel}>{label}</div> */}
            {/* <IconButton className={classes.btn} onClick={() => changePageHandler("next")}> */}
            {/* <NextIcon fontSize="large" /> */}
            {/* </IconButton> */}
          </div>
          {data.length > 0 && (
            <ResponsiveContainer height={300} width="100%">
              <AreaChart data={data} height={250} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="5%" stopColor={red[200]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={red[200]} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis allowDataOverflow allowDuplicatedCategory={false} dataKey="time" tickFormatter={formatXAxis} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                {data.map(d => (
                  <Area
                    data={d.data}
                    dataKey="value"
                    fill={d.color}
                    fillOpacity={0.5}
                    name={d.name}
                    stroke={d.color}
                    type="monotone"
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper className={classes.box}>
          <div className={classes.chartsAction}>
            <IconButton className={classes.btn} onClick={() => setCurrentData2(data2)}>
              <PrevIcon fontSize="large" />
            </IconButton>
            <IconButton className={classes.btn} onClick={() => setCurrentData2(data2Next)}>
              <NextIcon fontSize="large" />
            </IconButton>
          </div>
          <ResponsiveContainer height={300} width="99%">
            <BarChart
              data={currentData2}
              height={300}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill={red[200]} fillOpacity={1} stackId="a" />
              <Bar dataKey="uv" fill={green[200]} fillOpacity={1} stackId="a" />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid> */}
    </Grid>
  )
}

export default withStyles(styles)(AnalysisPage)
