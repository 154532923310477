// @ts-nocheck
import React from "react"

import { Edit as EditIcon } from "@mui/icons-material"
import { Grid, IconButton, Link, List, ListItem, Paper, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"

import styles from "./styles"

const InstallerPage = props => {
  const { classes } = props
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              Dane instalacji
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Nazwa instalacji</span>
                <span>Błonie2022</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Uruchomienie</span>
                <span>01.05.2022</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Opis</span>
                <span>-</span>
              </ListItem>
            </List>
            <div className={classes.footer}>
              <span>Ostatnia aktualizacja</span>
              <span>12.04.2022</span>
            </div>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              Dane instalatora
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Instalator</span>
                <span>Jan Kowalski</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Telefon kontaktowy</span>
                <span>
                  <Link className={classes.link} href="tel:+48867123123">
                    +48 867 123 123
                  </Link>
                </span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>E-mail</span>
                <span>
                  <Link className={classes.link} href="mailto:jan.kowalski@wellsolar.pl">
                    jan.kowalski@wellsolar.pl
                  </Link>
                </span>
              </ListItem>
            </List>
            <div className={classes.footer}>
              <span>Ostatnia aktualizacja</span>
              <span>01.04.2022</span>
            </div>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              Moduły fotowoltaiczne
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Moc znamionowa AC</span>
                <span>17 kW</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Moc modułów fotowoltaicznych</span>
                <span>23,28 kWp</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Pochylenie</span>
                <span>0</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Ustawienie</span>
                <span>0</span>
              </ListItem>
            </List>
            <div className={classes.footer}>
              <span>Ostatnia aktualizacja</span>
              <span>01.05.2022</span>
            </div>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              Dostęp zewnętrzny
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Zewnętrzna parametryzacja</span>
                <span className={classes.success}>Aktywna</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Dostęp dla serwisu</span>
                <span className={classes.success}>Aktywna</span>
              </ListItem>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Transmisja danych</span>
                <span className={classes.warning}>Średnia</span>
              </ListItem>
            </List>
            <div className={classes.footer}>
              <span>Ostatnia aktualizacja</span>
              <span>01.04.2022</span>
            </div>
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper className={classes.box}>
            <Typography className={classes.title} variant="body1">
              Automatyczne aktualizacje
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <span className={classes.itemLabel}>Automatyczne aktualizacje</span>
                <span className={classes.error}>Nieaktywna</span>
              </ListItem>
            </List>
            <div className={classes.footer}>
              <span>Ostatnia aktualizacja</span>
              <span>01.02.2022</span>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(InstallerPage)
