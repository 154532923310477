import React from "react"

import { Box, Grid, Typography, Tooltip } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import classNames from "classnames"

import styles from "./styles"

const Title = props => {
  const { header, Icon, status, statusText, classes } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.inlineHeader}>
          <Typography className={classes.header} variant="h5">
            {Icon && <Icon className={classes.icon} fontSize="big" />}
            <span>{header}</span>
          </Typography>
          {status && (
            <Box sx={{ marginBottom: "16px", display: "flex", alignItems: "center" }}>
              <Tooltip title={statusText}>
                <span
                  className={classNames(classes.dot, {
                    [classes.disconnect]: status === "disconnect",
                    [classes.connected]: status === "connected",
                    [classes.connecting]: status === "connecting",
                  })}
                />
              </Tooltip>
              <div>
                <div>{statusText}</div>
                {status === "connected" && <div style={{ fontSize: 10 }}>[SOLAR INVERTER V01.05]</div>}
              </div>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Title)
