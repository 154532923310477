/* TOKEN */
const URL_TOKEN = "/api/token/"
const URL_TOKEN_REFRESH = "/api/token/refresh/"

const URL_GRAPH = "/api/graph/"
const URL_DEVICES = "/api/devices/"
const URL_PARAMETERS = "/api/parameters/"

const URL_DATA_UUID = uuid => `/api/data/${uuid}/`

export { URL_DEVICES, URL_TOKEN_REFRESH, URL_TOKEN, URL_PARAMETERS, URL_DATA_UUID, URL_GRAPH }
