import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  primary: {
    main: "#0d1c27",
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#255475",
      main: "#19384e",
      dark: "#0d1c27",
      text: "#fff",
    },
    secondary: {
      light: "#fe4901",
      main: "#f5793b",
    },
  },
  typography: {
    fontFamily: ['"Lato"', "sans-serif"].join(","),
  },
  padding: {
    default: 15,
  },
  margin: {
    default: 30,
  },
})

export default theme
