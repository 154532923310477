import axios from "axios"

const domain = "https://test.app.wellsolar.com"

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const config = {
  mode: "cors",
}

const authorization = token => (headers.Authorization = `Bearer ${token}`)

export async function getRequest(apiUrl, token, params = {}) {
  if (token) authorization(token)
  return axios
    .get(`${domain}${apiUrl}`, { headers, config, params })
    .then(response => response)
    .catch(err => err)
}

export async function postRequest(apiUrl, payload, token, params = {}) {
  if (token) authorization(token)
  return axios
    .post(`${domain}${apiUrl}`, payload, { headers, config, params })
    .then(response => response)
    .catch(err => err)
}

export async function patchRequest(apiUrl, payload, token, params = {}) {
  if (token) authorization(token)
  return axios
    .patch(`${domain}${apiUrl}`, payload, { headers, config, params })
    .then(response => response)
    .catch(err => err)
}

export async function deleteRequest(apiUrl, token, params = {}) {
  if (token) authorization(token)
  return axios
    .delete(`${domain}${apiUrl}`, { headers, config, params })
    .then(response => response)
    .catch(err => err)
}
