import React, { memo } from "react"

import withStyles from "@mui/styles/withStyles"
import classNames from "classnames"

import styles from "./styles"

const TimeTab = props => {
  const { name, onClick, selected, classes } = props

  return (
    <li aria-hidden="true" className={classNames(classes.option, { [classes.active]: selected })} onClick={onClick}>
      {name}
    </li>
  )
}

export default memo(withStyles(styles)(TimeTab))
