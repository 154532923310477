import React, { useContext } from "react"

import {
  CheckCircleOutline as SuccessIcon,
  WarningAmber as WarningIcon,
  ErrorOutline as ErrorIcon,
} from "@mui/icons-material"
import { List, ListItem } from "@mui/material"
import { withStyles } from "@mui/styles"
import classNames from "classnames"

import { NotificationsContext } from "../../contexts/Notifications"
import styles from "./styles"

function Notifications(props) {
  const { classes } = props
  const [state] = useContext(NotificationsContext)

  return (
    <List className={classNames(classes.wrapper, { [classes.show]: state.open })}>
      <ListItem className={classes.item}>
        <WarningIcon className={classNames(classes.icon, classes.warning)} color="warning" fontSize="small" /> Falownik
        1 został zrestartowany
      </ListItem>
      <ListItem className={classes.item}>
        <ErrorIcon className={classNames(classes.icon, classes.error)} color="error" fontSize="small" /> Problem z
        komunikacją z fazą 1
      </ListItem>
      <ListItem className={classes.item}>
        <SuccessIcon className={classNames(classes.icon, classes.success)} color="success" fontSize="small" />{" "}
        Urządzenie 1CDx zainstalowano poprawnie
      </ListItem>
    </List>
  )
}

export default withStyles(styles)(Notifications)
