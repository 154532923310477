const styles = theme => ({
  box: {
    position: "relative",
    padding: theme.padding.default,
    color: theme.palette.text.secondary,
    background: theme.palette.primary.main,
    boxShadow: `0 0 10px ${theme.palette.primary.light}`,
  },
  title: {
    display: "flex",
    marginBottom: 20,
    textAlign: "center",
    justifyContent: "space-between",
    color: `${theme.palette.text.main}`,

    "& svg": {
      fill: `${theme.palette.text.main}`,
    },
  },
  staticHeight: {
    height: "auto",

    [theme.breakpoints.up("md")]: {
      height: "100%",
      overflow: "hidden",
    },
  },
  tooltip: {
    padding: 15,
    background: "rgba(25,56,78, .9)",
    border: "1px solid #19384E",
    boxShadow: "0 0 10px #000",
  },
  label1: {
    color: "#ee9a9a",
  },
  label2: {
    color: "#82ca9d",
  },
})

export default styles
