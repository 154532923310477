const styles = theme => ({
  background: {
    position: "relative",
    height: "100vh",
    width: "100%",
    background: theme.palette.primary.light,
  },
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    margin: "0 auto",
    padding: 30,
    maxWidth: 600,

    background: theme.palette.primary.main,
    color: `${theme.palette.text.secondary}`,
    boxShadow: `0 0 10px ${theme.palette.primary.main}`,

    textAlign: "center",
  },

  logo: {
    maxWidth: "250px",
    width: "100%",
  },

  text: {
    marginBottom: 30,
  },

  formFooter: {
    display: "block",
    textAlign: "center",
  },

  btn: {
    background: `${theme.palette.secondary.light}`,

    "&:hover": {
      background: `${theme.palette.secondary.main}`,
    },
  },

  divider: {
    margin: "0 auto 30px",

    maxWidth: "400px",
    borderColor: `${theme.palette.secondary.light}`,
  },
})

export default styles
