// @ts-nocheck
import React, { useState } from "react"

import { OutlinedInput, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

import theme from "../../theme"
import styles from "./styles"

const BoxFilterItem = props => {
  const { classes, id, items, inputLabelId, inputName, getFilters } = props
  const [selected, setSelected] = useState([])

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 4
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        background: `${theme.palette.primary.light}`,
        color: `${theme.palette.primary.text}`,
      },
    },
  }

  const handleChange = event => {
    const { target } = event
    setSelected(target.value)
    getFilters(target.value)
  }

  const generateSelectItems = groups => {
    const itemsTmp = []

    groups.map(group => {
      itemsTmp.push(<MenuItem className={classes.groupTitle}>{group.name}</MenuItem>)
      return group.parameters.map(item =>
        itemsTmp.push(
          <MenuItem className={classes.item} name={item.name} value={item.variable_name}>
            <Checkbox checked={selected.indexOf(item.variable_name) > -1} className={classes.checkbox} />
            <ListItemText primary={item.name} />
          </MenuItem>
        )
      )
    })

    return itemsTmp
  }

  return (
    <FormControl className={classes.formControl} size="small" variant="outlined">
      <InputLabel className={classes.selectLabel} id={inputLabelId}>
        {inputName}
      </InputLabel>
      <Select
        className={classes.select}
        id={id}
        input={<OutlinedInput label="Tag" />}
        labelId={inputLabelId}
        MenuProps={MenuProps}
        multiple
        name={inputName}
        onChange={handleChange}
        renderValue={() => selected.join(", ")}
        value={selected}
      >
        {generateSelectItems(items.groups).map(group => group)}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(BoxFilterItem)
