/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react"

import { WbSunny as SunIcon, WbTwilight as CloudIcon } from "@mui/icons-material"
import { FormControlLabel, FormGroup, Grid, Slider, Switch, Stack, Typography, Paper } from "@mui/material"
import { red } from "@mui/material/colors"
import { withStyles } from "@mui/styles"
import { Box } from "@mui/system"
import classNames from "classnames"

import { getRequest } from "helpers/axios"

import styles from "../styles"
import CircleChart from "./CircleChart"

const LOADBALANCER_URL = "/api/loadbalancer/"
const LOADBALANDER_TIMEOUT = 300

const EnergyFlowChart = props => {
  const { classes, setIsOverflow } = props

  const [maxPower, setMaxPower] = useState(5000)
  const [phaseMaxPower, setPhaseMaxPower] = useState({
    L1: 0,
    L2: 0,
    L3: 0,
  })
  const [phaseOverload, setPhaseOverload] = useState({
    L1: 0,
    L2: 0,
    L3: 0,
  })
  const [phases, setPhases] = useState({
    L1: {
      microwave: 0,
      vacuum: 0,
      gelsko: 0,
    },
    L2: {
      kettle: 0,
      washmachine: 0,
    },
    L3: {
      oven: 0,
    },
  })

  const [phaseLoad, setPhaseLoad] = useState({
    L1: 0,
    L2: 0,
    L3: 0,
  })

  const [lineBalancer, setLineBalancer] = useState(false)

  /** Usage of phase in percent  */
  const [phaseUsagePercent, setPhaseUsagePercent] = useState({
    L1: 34,
    L2: 33,
    L3: 33,
  })

  const [phaseOverloadTotal, setPhaseOverloadTotal] = useState(0)
  const [phaseOverloadWithoutLBTotal, setPhaseOverloadWithoutLBTotal] = useState(0)

  const calculatePhasePercent = (phase, total) => (100 * phase) / total

  const calculatePhaseOverload = () => {
    const L1Sum = Object.values(phases.L1).reduce((a, b) => a + b)
    const L2Sum = Object.values(phases.L2).reduce((a, b) => a + b)
    const L3Sum = Object.values(phases.L3).reduce((a, b) => a + b)

    setPhaseLoad({ L1: L1Sum, L2: L2Sum, L3: L3Sum })
  }

  const getLoadbalancer = () => {
    const data = {
      p: maxPower,
      load1: phaseLoad.L1,
      load2: phaseLoad.L2,
      load3: phaseLoad.L3,
    }

    if (lineBalancer) data.line_balancer = 1

    const mainRequest = getRequest(LOADBALANCER_URL, undefined, data).then(res => {
      const { generated_from_OZE, taken_from_grid } = res.data

      const sum = generated_from_OZE.reduce((prev, curr) => prev + curr)

      setPhaseUsagePercent({
        L1: calculatePhasePercent(generated_from_OZE[0], sum),
        L2: calculatePhasePercent(generated_from_OZE[1], sum),
        L3: calculatePhasePercent(generated_from_OZE[2], sum),
      })

      setPhaseMaxPower({
        L1: generated_from_OZE[0],
        L2: generated_from_OZE[1],
        L3: generated_from_OZE[2],
      })

      setPhaseOverload({
        L1: taken_from_grid[0],
        L2: taken_from_grid[1],
        L3: taken_from_grid[2],
      })

      setIsOverflow(!!taken_from_grid.find(p => p > 0))
    })

    const withoutLineBalancer = getRequest(LOADBALANCER_URL, undefined, {
      p: maxPower,
      load1: phaseLoad.L1,
      load2: phaseLoad.L2,
      load3: phaseLoad.L3,
    }).then(res => {
      const { taken_from_grid } = res.data

      setPhaseOverloadWithoutLBTotal(
        Object.values(taken_from_grid)
          .reduce((a, b) => a + b)
          .toFixed(0)
      )
    })

    Promise.all(mainRequest, withoutLineBalancer)
  }

  useEffect(() => {
    const loadBalancerTimeout = setTimeout(() => getLoadbalancer(), LOADBALANDER_TIMEOUT)
    return () => clearTimeout(loadBalancerTimeout)
  }, [maxPower, lineBalancer, phaseLoad])

  const switchHandler = useCallback(
    e => {
      const { name, value, checked } = e.target
      const [phase, device] = name.split("-")

      const _value = checked ? value : 0

      setPhases({ ...phases, [phase]: { ...phases[phase], [device]: parseInt(_value, 10) } })
    },
    [phases]
  )

  useEffect(() => calculatePhaseOverload(), [phases])

  const sumPhaseOverloadSum = () =>
    Object.values(phaseOverload)
      .reduce((a, b) => a + b)
      .toFixed(0)
  useEffect(() => setPhaseOverloadTotal(sumPhaseOverloadSum()), [phaseOverload])

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.p} variant="body1">
            Moc instalacji: <span className={classes.highlightGreen}>{maxPower} W</span>
          </Typography>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 1 }}>
            <CloudIcon />
            <Slider
              aria-label="Default"
              className={classes.slider}
              defaultValue={5000}
              max={10000}
              min={0}
              onChange={e => setMaxPower(e.target.value)}
            />
            <SunIcon className={classes.sunIcon} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.charWrapper}>
            <CircleChart
              lineBalancer={lineBalancer}
              phaseLoad={phaseLoad}
              phaseMaxPower={phaseMaxPower}
              phaseOverload={phaseOverload}
              phaseUsagePercent={phaseUsagePercent}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.balancerTotal} elevation={10}>
            <div>
              Zakup z sieci:{" "}
              {lineBalancer && (
                <div className={classNames(classes.balancerTotalValue, classes.overload)}>
                  <strike>{phaseOverloadWithoutLBTotal}W</strike>
                </div>
              )}
              <div className={classNames(classes.balancerTotalValue, classes.overload)}>{phaseOverloadTotal}W</div>
            </div>
            {lineBalancer && (
              <div>
                Z line balancerem oszczędzasz:{" "}
                <div className={classNames(classes.balancerTotalValue, classes.savings)}>
                  {(phaseOverloadWithoutLBTotal - phaseOverloadTotal).toFixed(0)}W
                  <div className={classes.balancerTotalValue}>
                    (
                    {(((phaseOverloadWithoutLBTotal - phaseOverloadTotal) / phaseOverloadWithoutLBTotal) * 100).toFixed(
                      0
                    ) | 0}
                    %)
                  </div>
                </div>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Switch color="success" onChange={e => setLineBalancer(e.target.checked)} value={lineBalancer} />
              }
              label="Line balancer"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.inline}>
            <FormGroup className={classNames(classes.formGroup)}>
              <div>Faza L1</div>
              <FormControlLabel
                control={<Switch color="success" name="L1-microwave" onChange={switchHandler} value={700} />}
                label="Mikrofalówka [700 W]"
              />
              <FormControlLabel
                control={<Switch color="success" name="L1-vacuum" onChange={switchHandler} value={1500} />}
                label="Odkurzacz [1500 W]"
              />
              <FormControlLabel
                control={<Switch color="success" name="L1-gelsko" onChange={switchHandler} value={2200} />}
                label="Żelazko [2200 W]"
              />
              {phaseOverload.L1 > 0 && (
                <>
                  <hr />
                  <div style={{ color: red[200] }}>
                    Energia kupiona z sieci: {phaseOverload.L1.toFixed(0)}
                    &nbsp;W
                  </div>
                </>
              )}
            </FormGroup>
            <FormGroup className={classNames(classes.formGroup)}>
              <div>Faza L2</div>
              <FormControlLabel
                control={<Switch color="success" name="L2-kettle" onChange={switchHandler} value={2000} />}
                label="Czajnik [2000 W]"
              />
              <FormControlLabel
                control={<Switch color="success" name="L2-washmachine" onChange={switchHandler} value={1200} />}
                label="Pralka [1200 W]"
              />
              {phaseOverload.L2 > 0 && (
                <>
                  <hr />
                  <div style={{ color: red[200] }}>Energia kupiona z sieci: {phaseOverload.L2.toFixed(0)}</div>
                </>
              )}
            </FormGroup>
            <FormGroup className={classNames(classes.formGroup)}>
              <div>Faza L3</div>
              <FormControlLabel
                control={<Switch color="success" name="L3-oven" onChange={switchHandler} value={3600} />}
                label="Piekarnik [3600 W]"
              />
              {phaseOverload.L3 > 0 && (
                <>
                  <hr />
                  <div style={{ color: red[200] }}>
                    Energia kupiona z sieci: {phaseOverload.L3.toFixed(0)}
                    &nbsp;W
                  </div>
                </>
              )}
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(EnergyFlowChart)
