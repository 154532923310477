const styles = theme => ({
  title: {
    textTransform: "uppercase",
    textAlign: "center",
  },
  value: {
    fontSize: "2rem",
    textAlign: "center",
  },
  chart: {
    padding: "15px 0",
  },
  paper: {
    padding: theme.padding.default,
    color: theme.palette.text.secondary,
    background: theme.palette.primary.main,
    boxShadow: `0 0 10px ${theme.palette.primary.light}`,
  },
  icon: {
    float: "right",
  },
  odometer: {
    textAlign: "center",
    padding: "20px 0 0",
  },
  unit: {
    margin: "15px 0 0 0",
    fontSize: "1.5rem",
  },
  price: {
    textAlign: "center",
  },
})

export default styles
