/* eslint-disable default-case */
import * as React from "react"

const initialState = {
  status: "error",
  message: "",
  open: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION":
      return { ...state, status: action.payload.status, message: action.payload.message, open: action.payload.open }
    case "SET_OPEN":
      return { ...state, open: action.payload.open }
  }
}

const NotificationsContext = React.createContext({
  state: initialState,
  dispatch: () => null,
})

const NotificationsProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return <NotificationsContext.Provider value={[state, dispatch]}>{props.children}</NotificationsContext.Provider>
}

export { NotificationsContext, NotificationsProvider }
