import React from "react"

import { Stack, Switch as MuiSwitch, Typography } from "@mui/material"

const Switch = props => {
  const { state, setState } = props
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Typography>Off</Typography>
      <MuiSwitch checked={state} onChange={() => setState(!state)} />
      <Typography>On</Typography>
    </Stack>
  )
}

export default Switch
